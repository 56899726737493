import React from 'react';

import { useModal } from '@tager/web-components';
import { isNotNullish } from '@tager/web-core';

import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import {
  submitCarModel,
  submitCarModelTestDrive,
} from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { ButtonsPanel } from '@/components/ButtonsPanel';

function Panel() {
  const {
    heroBrand,
    heroModel,
    panelFirstModalButtonTitle,
    panelFirstModalFormTitle,
    panelFirstModalGoogleEvent,
    panelSecondModalButtonTitle,
    panelSecondModalFormTitle,
    panelSecondModalGoogleEvent,
    panelFirstButtonTitle,
    panelFirstButtonLink,
    panelFirstButtonIsNewTab,
    panelSecondButtonTitle,
    panelSecondButtonLink,
    panelSecondButtonIsNewTab,
  } = useCarModelLandingData();
  const openModal = useModal();

  if (
    !panelFirstModalButtonTitle &&
    !panelSecondModalButtonTitle &&
    !panelFirstButtonTitle &&
    !panelSecondButtonTitle
  ) {
    return null;
  }

  const sendApplicationForm = async (
    type: 'carmodel' | 'testdrive',
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      if (type === 'carmodel') {
        await submitCarModel({
          ...values,
          model: `${heroBrand} ${heroModel}`,
        }).then(() => {
          if (panelFirstModalGoogleEvent) {
            googleEvent(panelFirstModalGoogleEvent, {
              car: `${heroBrand} ${heroModel}`,
            });
          }
        });
      } else {
        await submitCarModelTestDrive(values).then(() => {
          if (panelSecondModalGoogleEvent) {
            googleEvent(panelSecondModalGoogleEvent, {
              car: `${heroBrand} ${heroModel}`,
            });
          }
        });
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = (type: 'carmodel' | 'testdrive') => {
    openModal(
      ApplicationFormModal,
      {
        title:
          (type === 'carmodel'
            ? panelFirstModalFormTitle
            : panelSecondModalFormTitle) ?? '',
        onSubmit: (values) => sendApplicationForm(type, values),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <ButtonsPanel
      buttons={[
        panelFirstModalButtonTitle
          ? {
              title: panelFirstModalButtonTitle,
              onClick: () => handleOpenApplicationFormModal('carmodel'),
            }
          : undefined,
        panelSecondModalButtonTitle
          ? {
              title: panelSecondModalButtonTitle,
              onClick: () => handleOpenApplicationFormModal('testdrive'),
            }
          : undefined,
        panelFirstButtonTitle
          ? {
              title: panelFirstButtonTitle,
              link: panelFirstButtonLink ?? '',
              isNewTab: panelFirstButtonIsNewTab,
            }
          : undefined,
        panelSecondButtonTitle
          ? {
              title: panelSecondButtonTitle,
              link: panelSecondButtonLink ?? '',
              isNewTab: panelSecondButtonIsNewTab,
            }
          : undefined,
      ].filter(isNotNullish)}
    />
  );
}

export default Panel;
