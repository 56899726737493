import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

import { CarCardProps } from './types';

export function CarCard({
  image,
  name,
  price,
  link,
  isNewTab,
  isMobile,
}: CarCardProps) {
  const content = (
    <Inner isMobile={isMobile}>
      <Image
        loading="lazy"
        {...convertThumbnailToPictureProps(image)}
        isMobile={isMobile}
        alt={name}
      />

      <Info isMobile={isMobile}>
        <Name isMobile={isMobile}>{name}</Name>
        {price && <Price isMobile={isMobile}>{price}</Price>}
      </Info>
    </Inner>
  );

  return !link ? (
    <Component isMobile={isMobile}>{content}</Component>
  ) : (
    <ComponentLink
      to={link}
      target={isNewTab ? '_blank' : '_self'}
      isMobile={isMobile}
    >
      {content}
    </ComponentLink>
  );
}

const ComponentStyles = css<{ isMobile?: boolean }>`
  position: relative;
  border: 1px solid ${(props) => props.theme.gray1300_alpha50};
  border-radius: 10px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      border: none;
    `}
`;

const Name = styled.p<{ isMobile?: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) => props.theme.black200};
  white-space: nowrap;
  transition: 0.3s;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 14px;
      line-height: 20px;
    `}
`;

const Price = styled.p<{ isMobile?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  margin-top: 5px;
  color: ${(props) => props.theme.black200};

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 14px;
    `}
`;

const Component = styled.div<{ isMobile?: boolean }>`
  ${ComponentStyles}
`;

const ComponentLink = styled(Link)<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  transition: 0s;

  ${ComponentStyles}

  &:hover {
    ${Name} {
      color: ${colors.main};
    }
  }
`;

const Inner = styled.div<{ isMobile?: boolean }>`
  position: relative;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const Image = styled(Picture)<{ isMobile?: boolean }>`
  position: relative;
  padding-top: 79.14%;
  border-radius: 10px 10px 0 0;
  background: ${colors.gray200};
  width: 100%;

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }

  img {
    object-fit: scale-down;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
      padding-top: 0;
      border-radius: 10px;

      img,
      picture {
        border-radius: 10px;
      }
    `}
`;

const Info = styled.div<{ isMobile?: boolean }>`
  position: relative;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background: ${(props) => props.theme.white_1};
  overflow: hidden;

  &::after {
    content: '';
    background: ${(props) => props.theme.whiteGradient};
    right: 0;
    top: 0;
    bottom: 0;
    width: 75px;
    height: 100%;
    display: block;
    position: absolute;
    pointer-events: none;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex: 1;
      width: 100%;
      border-radius: 10px;
    `}
`;
