import { Nullable } from '@tager/web-core';

import { CallbackFormFields } from '@/components/modals/CallbackForm/CallbackForm.types';
import CallbackForm from '@/components/modals/CallbackForm';
import CallbackFormSecondary from '@/components/modals/CallbackFormSecondary';
import { googleEvent } from '@/utils/events';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { OpenModalType, OptionType } from '@/typings/common';
import { CallbackFormSecondaryFields } from '@/components/modals/CallbackFormSecondary/types';
import {
  submitCarSubscription,
  submitCarAdv,
  submitCarCaraccidenthelp,
} from '@/services/leads/leads-service';

async function handleSubmitCarSubscriptionForm(
  values: CallbackFormFields
): Promise<void> {
  try {
    await submitCarSubscription(values);
  } catch (error: any) {
    return Promise.reject(error);
  }
}

async function handleSubmitCarAdvForm(
  values: CallbackFormFields
): Promise<void> {
  try {
    await submitCarAdv(values);
  } catch (error: any) {
    return Promise.reject(error);
  }
}

async function handleSubmitCarracidentHelpForm(
  values: CallbackFormSecondaryFields
): Promise<void> {
  const { brand, ...resultValues } = values;

  try {
    await submitCarCaraccidenthelp({
      ...resultValues,
      dealers: values.brand.value.split(',').map((item) => Number(item)),
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export function handleOpenModalFormClick(
  openModal: OpenModalType,
  formType: Nullable<'PODPISKA' | 'CARACCIDENTHELP' | 'ADV'>,
  brands: OptionType[],
  formEvent: Nullable<string>
) {
  switch (formType) {
    case 'PODPISKA':
    case 'ADV':
      return openModal(
        CallbackForm,
        {
          title: 'Оставить заявку',
          fieldsConfig: { withMessage: true },
          request: (values) =>
            (formType === 'PODPISKA'
              ? handleSubmitCarSubscriptionForm(values)
              : handleSubmitCarAdvForm(values)
            ).then(() => {
              if (formEvent) {
                googleEvent(formEvent);
              }
            }),
        },
        { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
      );
    case 'CARACCIDENTHELP':
      return openModal(
        CallbackFormSecondary,
        {
          title: 'Оставить заявку',
          brands,
          onSubmit: (values) =>
            handleSubmitCarracidentHelpForm(values).then(() => {
              if (formEvent) {
                googleEvent(formEvent);
              }
            }),
        },
        { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
      );
    default:
      return openModal(
        CallbackForm,
        {
          title: 'Оставить заявку',
          fieldsConfig: { withMessage: true },
          request: (values) =>
            handleSubmitCarSubscriptionForm(values).then(() => {
              if (formEvent) {
                googleEvent(formEvent);
              }
            }),
        },
        { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
      );
  }
}
