import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { useSollersHomeData } from '@/modules/SollersHome/hooks';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { ContentContainer } from '@/modules/SollersHome/common.styles';
import { media } from '@/utils/mixin';
import { ServiceCard } from '@/components/LandingWidgets';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitSollersCarsLeadForm } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { getDealersOptions } from '@/utils/common';

function SpecialOfferBanner() {
  const openModal = useModal();
  const {
    specialOfferTitle,
    specialOfferText,
    specialOfferNote,
    specialOfferImage,
    specialOfferMobileImage,
    specialOfferButtonText,
    specialOfferButtonLink,
    specialOfferFormTitle,
    specialOfferGoogleEvent,
    dealersRequests,
  } = useSollersHomeData();

  if (!specialOfferTitle || !specialOfferText || !specialOfferImage) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitSollersCarsLeadForm({
        ...values,
        dealer: values.dealer ? Number(values.dealer.value) : undefined,
      }).then(() => {
        if (specialOfferGoogleEvent) {
          googleEvent(specialOfferGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: specialOfferFormTitle ?? '',
        fieldsConfig: { dealers: getDealersOptions(dealersRequests) },
        onSubmit: sendApplicationForm,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <ServiceCard
          title={specialOfferTitle}
          description={specialOfferText}
          note={specialOfferNote ?? ''}
          image={specialOfferImage}
          imageMobile={specialOfferMobileImage}
          buttonText={specialOfferButtonText ?? ''}
          buttonLink={specialOfferButtonLink ?? ''}
          onSubmit={handleOpenApplicationFormModal}
        />
      </ContentContainer>
    </Component>
  );
}

export default SpecialOfferBanner;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
