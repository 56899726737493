import React from 'react';

import { useModal } from '@tager/web-components';
import { isNotNullish } from '@tager/web-core';

import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { useTheLineupData } from '@/modules/TheLineup/hooks';
import { ButtonsPanel } from '@/components/ButtonsPanel';
import {
  handleSubmitTheLineupCar,
  handleSubmitTheLineupTestDrive,
} from '@/modules/TheLineup/common.helpers';

function Panel() {
  const {
    templateFields: {
      panelFirstModalButtonTitle,
      panelFirstModalFormTitle,
      panelFirstModalGoogleEvent,
      panelSecondModalButtonTitle,
      panelSecondModalFormTitle,
      panelSecondModalGoogleEvent,
      panelFirstButtonTitle,
      panelFirstButtonLink,
      panelFirstButtonIsNewTab,
    },
  } = useTheLineupData();
  const openModal = useModal();

  if (
    !panelFirstModalButtonTitle &&
    !panelSecondModalButtonTitle &&
    !panelFirstButtonTitle
  ) {
    return null;
  }

  const handleOpenApplicationFormModal = (type: 'car' | 'testdrive') => {
    openModal(
      ApplicationFormModal,
      {
        title:
          (type === 'car'
            ? panelFirstModalFormTitle
            : panelSecondModalFormTitle) ?? '',
        onSubmit: (values) =>
          type === 'car'
            ? handleSubmitTheLineupCar(values, panelFirstModalGoogleEvent ?? '')
            : handleSubmitTheLineupTestDrive(
                values,
                panelSecondModalGoogleEvent ?? ''
              ),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <ButtonsPanel
      buttons={[
        panelFirstModalButtonTitle
          ? {
              title: panelFirstModalButtonTitle,
              onClick: () => handleOpenApplicationFormModal('car'),
            }
          : undefined,
        panelSecondModalButtonTitle
          ? {
              title: panelSecondModalButtonTitle,
              onClick: () => handleOpenApplicationFormModal('testdrive'),
            }
          : undefined,
        panelFirstButtonTitle
          ? {
              title: panelFirstButtonTitle,
              link: panelFirstButtonLink ?? '',
              isNewTab: panelFirstButtonIsNewTab,
            }
          : undefined,
      ].filter(isNotNullish)}
    />
  );
}

export default Panel;
