import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import About from './components/About';
import Benefits from './components/Benefits';
import Insurance from './components/Insurance';
import Form from './components/Form';

function VedaemLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <About />
        <Benefits />
        <Insurance />
        <Form />
      </Component>
    </Layout>
  );
}

export default VedaemLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
