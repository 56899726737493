import React from 'react';
import styled, { keyframes } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';

import { CompaniesTickerProps } from './types';

const tickers = 3;

function CompaniesTicker({ images }: CompaniesTickerProps) {
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <Component>
      {new Array(tickers).fill(null).map((_, index) => (
        <List key={index}>
          {images.map((image, index) => (
            <Image
              key={index}
              loading="lazy"
              {...convertThumbnailToPictureProps(image)}
            />
          ))}
        </List>
      ))}
    </Component>
  );
}

export default CompaniesTicker;

const Component = styled.div`
  display: flex;
  position: relative;
  overflow-x: hidden;

  &::before,
  &::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 150px;
  }

  &::before {
    left: 0;
    background: ${(props) => props.theme.whiteGradient_2};
  }

  &::after {
    right: 0;
    background: ${(props) => props.theme.whiteGradient_1};
  }
`;

const tickerAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  
  100% {
    transform: translateX(0%);
  }

`;

const List = styled.div`
  display: flex;
  animation: ${tickerAnimation} 30s linear 0s infinite normal none running;
`;

const Image = styled(Picture)`
  width: 130px;
  height: 80px;
  margin-right: 40px;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
