import React from 'react';
import styled, { css } from 'styled-components';

import { useJetourHomeData } from '@/modules/JetourHome/hooks';
import { BannersSliderPrimary } from '@/components/LandingWidgets';
import { Container as BannersSliderPrimaryContainer } from '@/components/LandingWidgets/components/BannersSliderPrimary';
import { media } from '@/utils/mixin';

function HeroSlider() {
  const { heroBanners } = useJetourHomeData();

  if (!heroBanners || heroBanners.length === 0) {
    return null;
  }

  return (
    <Component>
      <BannersSliderPrimary
        id={1}
        banners={heroBanners.map(({ image, mobileImage, text, url }) => ({
          image,
          mobileImage,
          text: text ?? '',
          url: url ?? '',
        }))}
      />
    </Component>
  );
}

export default HeroSlider;

const Component = styled.section`
  ${BannersSliderPrimaryContainer} {
    padding-top: 51.283%;

    ${media.tablet(css`
      padding-top: initial;
      height: 800px;
    `)}
  }

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
