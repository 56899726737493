import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/TheLineup/common.styles';
import { useTheLineupData } from '@/modules/TheLineup/hooks';
import { ServiceFormSecondary } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';
import { handleSubmitTheLineupCar } from '@/modules/TheLineup/common.helpers';

function Form() {
  const {
    templateFields: {
      formTitle,
      formSubtitle,
      formImage,
      formMobileImage,
      formButtonTitle,
      formGoogleEvent,
    },
  } = useTheLineupData();

  if (!formTitle || !formImage) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <ServiceFormSecondary
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          image={formImage}
          buttonTitle={formButtonTitle ?? ''}
          imageMobile={formMobileImage}
          onSubmit={(values) =>
            handleSubmitTheLineupCar(values, formGoogleEvent ?? '')
          }
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
