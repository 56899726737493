import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { ContentContainer } from '@/modules/B2bHome/common.styles';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { getStockCarList } from '@/store/reducers/cars-stock';
import { useB2bHomeData } from '@/modules/B2bHome/hooks';
import { CarsStockList, Title } from '@/components/LandingWidgets';
import {
  getCarBadges,
  getCarImageFit,
  getCarImages,
  getCarLink,
  getCarPrice,
  getCarStatus,
  getCarTags,
  handleOpenApplicationModal,
} from '@/modules/CarsStock/components/common.helpers';
import { StockCarCardProps } from '@/components/StockCarCard/types';
import { Component as TitleComponent } from '@/components/LandingWidgets/components/Title';
import useSettingItem from '@/hooks/useSettingItem';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';

function Cars() {
  const {
    stockTitle,
    stockImage,
    stockButtonText,
    stockButtonLink,
    stockItemsQuantity,
    stockBrands,
  } = useB2bHomeData();
  const carsListData = useTypedSelector(getStockCarList);
  const openModal = useModal();
  const stockNewApplicationGoogleEvent = useSettingItem(
    'STOCK_NEW_APPLICATION_GOOGLE_EVENT'
  );
  const stockPartnerCarApplicationGoogleEvent = useSettingItem(
    'STOCK_PARTNER_CAR_APPLICATION_GOOGLE_EVENT'
  );
  const phone = usePhoneNumber();

  if (
    !stockItemsQuantity ||
    !stockBrands ||
    stockBrands.length === 0 ||
    !stockTitle ||
    !carsListData ||
    !carsListData.items ||
    carsListData.items.length === 0
  ) {
    return null;
  }

  const carsStockListData: StockCarCardProps[] = carsListData.items.map(
    (car) => ({
      view: 'portrait',
      images: getCarImages(car),
      imageFit: getCarImageFit(car),
      link: getCarLink('new', car.id),
      name: car.name,
      subName: car.complectation ?? '',
      price: getCarPrice(car, phone),
      totalImagesCount: car.imagesCount,
      tags: getCarTags(car),
      badgesList: getCarBadges(car),
      carStatus: getCarStatus(car),
      stockStatus: car.stockStatus ?? undefined,
      buttonHandle: () =>
        handleOpenApplicationModal(
          car,
          openModal,
          (car.isPartnerCar
            ? stockPartnerCarApplicationGoogleEvent
            : stockNewApplicationGoogleEvent) ?? ''
        ),
    })
  );

  return (
    <Component id="cars">
      <ContentContainer>
        <TitleWrapper>
          <Title title={stockTitle ?? ''} />
        </TitleWrapper>

        <CarsStockList
          id={3}
          cars={carsStockListData}
          image={stockImage}
          buttonTitle={stockButtonText ?? ''}
          buttonLink={stockButtonLink ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default Cars;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.tablet(css`
    ${TitleComponent} {
      text-align: left;
    }
  `)}

  ${media.mobile(css`
    ${TitleComponent} {
      font-size: 18px;
    }
  `)}
`;
